<template>
  <div>Hello</div>
</template>
<script>
export default {
  name: "Orders",
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
