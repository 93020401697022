<template>
  <MountingPortal mountTo="#loader-main" name="source" append>
    <div class="loader-main">
      <lottie :options="defaultOptions" :height="200" :width="200" />
    </div>
  </MountingPortal>
</template>
<script>
import { MountingPortal } from "portal-vue";
import Lottie from "vue-lottie";
import animationData from "../../assets/images/lottie.json";
export default {
  name: "GlobalLoader",
  data() {
    return {
      defaultOptions: { animationData, loop: true },
    };
  },
  components: {
    MountingPortal,
    Lottie,
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
