import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/index.vue";
import jwt_decode from "jwt-decode";
import store from "../store/index";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      guestOnly: true,
    },
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    meta: {
      guestOnly: true,
    },
    component: () => import("../views/signup/index.vue"),
  },
  {
    path: "/dashboard",
    component: () => import("../views/dashboard/index.vue"),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("../views/dashboard/main/index.vue"),
        meta: { title: "Dashboard", requireAuth: true },
      },
      {
        path: "orders",
        name: "Orders",
        component: () => import("../views/orders/index.vue"),
        meta: { title: "Orders", requireAuth: true },
      },
      {
        path: "add-funds",
        name: "AddFunds",
        component: () => import("../views/add_funds/index.vue"),
        meta: { title: "Add Funds", requireAuth: true },
      },
      {
        path: "services",
        name: "Services",
        component: () => import("../views/services/index.vue"),
        meta: { title: "Services", requireAuth: true },
      },
      {
        path: "tickets",
        name: "Tickets",
        component: () => import("../views/tickets/index.vue"),
        meta: { title: "Tickets", requireAuth: true },
      },
      {
        path: "referrals",
        name: "Referrals",
        component: () => import("../views/referrals/index.vue"),
        meta: { title: "Referrals", requireAuth: true },
      },
      {
        path: "child_panel",
        name: "ChildPanel",
        component: () => import("../views/child_panel/index.vue"),
        meta: { title: "Child Panel", requireAuth: true },
      },
      {
        path: "api",
        name: "Api",
        component: () => import("../views/api/index.vue"),
        meta: { title: "Api", requireAuth: true },
      },
      {
        path: "settings",
        name: "Settings",
        component: () => import("../views/settings/index.vue"),
        meta: { title: "Settings", requireAuth: true },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = JSON.parse(localStorage.getItem("auth_token"));
  if (to.matched.some((record) => record.meta.requireAuth) && !token) {
    next({
      name: "Login",
    });
    return;
  } else {
    if (token) {
      const decoded = jwt_decode(token);
      if (
        decoded.exp < Date.now() / 1000 ||
        decoded.exp === undefined ||
        decoded.exp === null ||
        !decoded.userId
      ) {
        localStorage.removeItem("auth_token");
        next({
          name: "Login",
        });
        return;
      } else if (to.matched.some((record) => record.meta.guestOnly)) {
        next({
          name: "Dashboard",
        });
        return;
      } else {
        if (store.state.isLoggedIn && store.state.user !== null) {
          next();
          return;
        }
        store.commit("setGlobalLoading", true);
        store
          .dispatch("getUser", decoded.userId)
          .then((e) => {
            store.commit("setGlobalLoading", false);
            store.commit("setUser", e);
            store.commit("setIsLoggedIn", true);
            next();
          })
          .catch(() => {
            localStorage.removeItem("auth_token");
            store.commit("setGlobalLoading", false);
            next({
              name: "Login",
            });
          });
      }
    }
    next();
    return;
  }

  // if (to.meta.requireAuth) {
  //   console.log("requireAuth");
  //   if (!token) {
  //     next({
  //       name: "Login",
  //       query: { redirect: to.fullPath },
  //     });
  //     return;
  //   } else {
  //     next();
  //   }
  // }
});

export default router;
