<template>
  <div>
    <header>
      <nav class="main-nav-inner">
        <div class="left-section">
          <div class="logo-section">
            <img class="main-logo" src="../../assets/images/logo.png" />
          </div>
          <ul class="nav-links">
            <li class="nav-link-item">
              <router-link to="/">Api</router-link>
            </li>
            <li class="nav-link-item">
              <router-link to="/"> Services</router-link>
            </li>
            <!-- <li class="nav-link-item">
              <router-link to="/"> Reset Password</router-link>
            </li>
            <li class="nav-link-item">
              <router-link to="/"> Chat</router-link>
            </li> -->
          </ul>
        </div>
        <div class="nav-right">
          <ul class="links">
            <router-link class="login link" :to="{ name: 'Login' }"
              >Login</router-link
            >
            <router-link :to="{ name: 'Signup' }">Signup</router-link>
          </ul>
          <span class="hamburger"
            ><i
              @click="isNavOpen = !isNavOpen"
              class="fa-solid fa-bars-staggered pointer"
            ></i>
          </span>
        </div>
      </nav>
    </header>
    <!-- sidebar -->
    <MountingPortal mountTo="#widget" name="source" append>
      <div @click="handleClose" :class="{ open: isNavOpen }" class="right-show">
        <div class="main-nav-items">
          <div class="logo">
            <img src="../../assets/images/logo.png" alt="Logo" />
          </div>
          <ul class="links-inner">
            <router-link :to="{ name: 'Home' }">
              <i class="fa-solid fa-house"></i
              ><span class="link-text"> Home</span></router-link
            >
            <router-link to="/api-docs">
              <i class="fa-solid fa-star-of-life"></i
              ><span class="link-text"> Api</span></router-link
            >
            <router-link to="/services">
              <i class="fa-solid fa-list"></i>
              <span class="link-text">Services</span></router-link
            >

            <!-- <router-link :to="{ name: 'ForgotPassword' }">
              <i class="fa-solid fa-unlock-keyhole"></i>
              <span class="link-text"> Reset Password</span></router-link
            >
            <router-link :to="{ name: 'Chat' }">
              <i class="fa-solid fa-comment"></i>
              <span class="link-text"> Chat </span></router-link
            > -->
            <router-link :to="{ name: 'Login' }">
              <i class="fa-solid fa-right-to-bracket"></i>
              <span class="link-text"> Login</span>
            </router-link>
            <router-link :to="{ name: 'Signup' }">
              <i class="fa-solid fa-user-plus"></i>
              <span class="link-text"> Signup</span></router-link
            >
          </ul>
        </div>
      </div>
    </MountingPortal>
  </div>
</template>
<script>
import { MountingPortal } from "portal-vue";
export default {
  name: "Header",
  components: {
    MountingPortal,
  },
  data() {
    return {
      isNavOpen: false,
    };
  },
  methods: {
    handleClose(e) {
      if (
        e.target.getAttribute("class") &&
        e.target.getAttribute("class").includes("right-show")
      ) {
        this.isNavOpen = false;
      }
    },
  },

  watch: {
    $route() {
      this.isNavOpen = false;
    },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
