import axios from "axios";

const uri =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_API
    : "https://api.thegainer.com.ng/api";

const Axios = axios.create({
  baseURL: uri,
  " Access-Control-Allow-Origin": "*",
  withCredentials: true,
});

Axios.interceptors.request.use(
  (request) => {
    const token = JSON.parse(localStorage.getItem("auth_token"));
    if (!token) return request;
    request.headers.Authorization = token;
    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default Axios;
