<template>
  <MountingPortal mountTo="#modal" name="source" append>
    <div
      @click="outsideClose"
      class="modal-background"
      :class="`${
        isOpened === true ? 'active' : isOpened === false ? 'inactive' : ''
      } ${customClass} ${animation}`"
    >
      <div
        :class="`modal-content ${contentCustomClass} ${
          showLoaderOverlay ? 'loading' : ''
        }`"
      >
        <div v-if="!hideHeader" class="modal-header">
          <div class="modal-title">
            <slot name="title" />
          </div>
          <div @click="close" class="modal-close">
            <slot v-if="showClose" name="close">
              <svg
                viewBox="0 0 12 12"
                role="presentation"
                aria-hidden="true"
                focusable="false"
                style="
                  height: 12px;
                  width: 12px;
                  display: block;
                  fill: currentcolor;
                "
              >
                <path
                  d="m11.5 10.5c.3.3.3.8 0 1.1s-.8.3-1.1 0l-4.4-4.5-4.5 4.5c-.3.3-.8.3-1.1 0s-.3-.8 0-1.1l4.5-4.5-4.4-4.5c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l4.4 4.5 4.5-4.5c.3-.3.8-.3 1.1 0s .3.8 0 1.1l-4.5 4.5z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </slot>
          </div>
        </div>
        <div class="modal-body">
          <div class="loading"></div>
          <slot name="body">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur,
            delectus minus. Dolores veritatis, voluptas amet atque, ab qui eaque
            fuga commodi at voluptates beatae, eum distinctio harum? Possimus
            rem rerum ab ad esse fugit maxime molestias nihil doloremque.
            Aspernatur at fugit dolores eligendi ex id provident doloribus
            incidunt? Minima, dignissimos.
          </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </MountingPortal>
</template>
<script>
import { MountingPortal } from "portal-vue";
export default {
  name: "Modal",
  data() {
    return {
      isOpened: null,
    };
  },
  props: {
    animation: {
      type: String,
      default: "slide-up",
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    clickOutsideToClose: {
      type: Boolean,
      default: true,
    },
    defaultOpened: {
      type: Boolean,
      default: false,
    },
    contentCustomClass: {
      type: String,
      default: "",
    },
    showLoaderOverlay: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MountingPortal,
  },
  methods: {
    outsideClose(e) {
      if (this.clickOutsideToClose) {
        if (
          e.target.getAttribute("class") &&
          e.target.getAttribute("class").includes("modal-background")
        ) {
          this.close();
        }
      }
    },
    open() {
      document.body.style.overflow = "hidden";
      this.isOpened = true;
    },
    close() {
      document.body.style.overflow = "auto";
      this.isOpened = false;
      this.$emit("closed");
    },
  },
  watch: {
    defaultOpened() {
      this.isOpened = this.defaultOpened;
    },
  },
  mounted() {
    this.isOpened = this.defaultOpened;
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
