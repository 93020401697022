import Vue from "vue";
import Vuex from "vuex";
import Axios from "../axios/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    user: null,
    globalLoading: false,
    isUserLoading: false,
    transactions: [],
    fetchingTransactions: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setIsUserLoading(state, isUserLoading) {
      state.isUserLoading = isUserLoading;
    },
    setGlobalLoading(state, globalLoading) {
      state.globalLoading = globalLoading;
    },
    setTransactions(state, transactions) {
      state.transactions = transactions;
    },
    addToTransactions(state, transaction) {
      state.transactions = [...state.transactions, transaction];
    },
    setIsFetchingTransaction(state, status) {
      state.fetchingTransactions = status;
    },
  },
  actions: {
    // async getUser({ commit, dispatch }, payload = true) {
    //   const { data } = await Axios.post("/get_user");
    //   if (data.status === "error") {
    //     commit(SET_LOGED_IN, false);
    //     return;
    //   }
    //   commit(SET_USER, data.data);
    //   if (payload) {
    //     commit(SET_LOGED_IN, true);
    //     dispatch(GET_SERVICES);
    //   }
    // },
    async getUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("setIsUserLoading", true);

        Axios.get(`/user/${payload}`)
          .then((res) => {
            commit("setIsUserLoading", false);
            if (res.data.status === "error") {
              return reject(res.data.message);
            }
            return resolve(res.data.data.user);
          })
          .catch((err) => {
            commit("setIsUserLoading", false);
            return reject(err.response.data.message);
          });
      });
    },
    async getTransactions({ state, commit }, payload = { page: 1, limit: 20 }) {
      return new Promise((resolve, reject) => {
        commit("setIsFetchingTransaction", true);

        Axios.get(
          `/user/${state.user.userId}/transactions?page=${payload.page}&limit=${payload.limit}`
        )
          .then((res) => {
            commit("setIsFetchingTransaction", false);
            commit("setTransactions", [
              ...state.transactions,
              ...res.data.data.transactions,
            ]);
            if (res.data.status === "error") {
              return reject(res.data.message);
            }
            return resolve(res.data.data.transactions);
          })
          .catch((err) => {
            commit("setIsFetchingTransaction", false);
            return reject(err.response.data.message);
          });
      });
    },
  },
  modules: {},
});
