import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import PortalVue from "portal-vue";
import VueMeta from "vue-meta";
import VueTelInput from "vue-tel-input";
import Axios from "./axios/index";
import VueAxios from "vue-axios";
import "vue-tel-input/dist/vue-tel-input.css";
import SweetModal from "sweet-modal-vue/src/plugin.js";
import Modal from "./components/modal/index";

Vue.config.productionTip = false;
Vue.use(PortalVue);
Vue.use(SweetModal);
Vue.use(require("vue-moment"));
Vue.component("Modal", Modal);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueTelInput, {
  validCharactersOnly: true,
  autofocus: false,
});
Vue.use(VueAxios, Axios);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
