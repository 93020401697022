<template>
  <!-- <v-app> -->
  <!-- <v-main> -->
  <main>
    <GlobalLoader v-if="isLoading" />
    <Header v-if="!$route.path.includes('/dashboard')" />
    <router-view />
  </main>

  <!-- </v-main> -->
  <!-- </v-app> -->
</template>

<script>
import { mapState } from "vuex";
import Header from "@/components/header/index.vue";
import GlobalLoader from "@/components/global-loader/index.vue";
export default {
  name: "App",

  components: {
    Header,
    GlobalLoader,
  },

  data: () => ({
    //
  }),
  computed: {
    ...mapState({
      isLoading: (state) => state.globalLoading,
    }),
  },
};
</script>
<style lang="scss">
@import "./App.scss";
</style>
